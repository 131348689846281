import { CtaBanner } from '@/design-system/molecules/CtaBanner';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const IntegrationCtaBanner = (props) => {
    const modifiers = generateStyleModifiers(props.appliedCssClassNames);
    const theme = modifiers.theme;
    const mediaShape = modifiers.imageShape;

    return <CtaBanner {...props} theme={theme} mediaShape={mediaShape} />;
};

export default IntegrationCtaBanner;
